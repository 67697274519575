import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  discPer: 0,
  discAmtByPer: 0,
  discAmt: 0,
  taxPer: 0,
  taxAmt: 0,
  other: 0,
  total: 0,
  netTotal: 0,
  SubTotFC: 0,
  NetTotFC: 0,
};

const purchaseImportDiscAndTaxSlice = createSlice({
  name: "purchaseImportDiscAndTax",
  initialState,
  reducers: {
    setDiscPer: (state, action) => {
      state.discPer = action.payload;
    },
    setDiscAmtByPer: (state, action) => {
      state.discAmtByPer = action.payload;
    },
    setDiscAmt: (state, action) => {
      state.discAmt = action.payload;
    },
    setTaxPer: (state, action) => {
      state.taxPer = action.payload;
    },
    setTaxAmt: (state, action) => {
      state.taxAmt = action.payload;
    },
    setOther: (state, action) => {
      state.other = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setNetTotal: (state, action) => {
      state.netTotal = action.payload;
    },
    setToDefaultCalc: (state) => {
      // Reset the state to its initial/default values directly
      state.discPer = 0;
      state.discAmtByPer = 0;
      state.discAmt = 0;
      state.taxPer = 0;
      state.taxAmt = 0;
      state.other = 0;
      state.total = 0;
      state.netTotal = 0;
    },
    setCalculation: (state, action) => {
      const { discPer, discAmt, taxPer, other, total, exChangeRate } =
        action.payload;
      state.discPer = discPer;
      state.discAmt = discAmt;
      state.taxPer = taxPer;
      state.other = other;
      state.total = total;
      state.discAmtByPer = (discPer * total) / 100;
      state.taxAmt = (taxPer * total) / 100;
      state.netTotal =
        total +
        other +
        (taxPer * total) / 100 -
        (discPer * total) / 100 -
        discAmt;
      state.SubTotFC = total / exChangeRate;
      state.NetTotFC =
        (total +
          other +
          (taxPer * total) / 100 -
          (discPer * total) / 100 -
          discAmt) /
        exChangeRate;
    },
  },
});

export const {
  setDiscPer,
  setDiscAmtByPer,
  setDiscAmt,
  setTaxPer,
  setTaxAmt,
  setOther,
  setTotal,
  setNetTotal,
  setToDefaultCalc,
  setCalculation,
} = purchaseImportDiscAndTaxSlice.actions;

export default purchaseImportDiscAndTaxSlice.reducer;
