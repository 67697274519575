import { createSlice } from "@reduxjs/toolkit";

const purchaseReturnTableDataSlice = createSlice({
  name: "purchaseReturnTableData",
  initialState: [],
  reducers: {
    addItemToTableData: (state, action) => {
      state.push(action.payload);
    },
    updateTableRowValue: (state, action) => {
      const { index, discAmt, qty, Cost, Tax1, batch, TaxEnabled } =
        action.payload;

      state[index].discAmt = discAmt;
      state[index].qty = qty;
      state[index].Cost = Cost;
      state[index].batch = batch;
      state[index].total = qty * Cost - discAmt;
      state[index].taxAmt = TaxEnabled
        ? (qty * Cost * Tax1) / (100 + Tax1)
        : ((qty * Cost - discAmt) * Tax1) / 100;
      state[index].netValue = TaxEnabled
        ? qty * Cost
        : qty * Cost - discAmt + ((qty * Cost - discAmt) * Tax1) / 100;
    },

    deleteItemFromTableData: (state, action) => {
      const index = state.findIndex(
        (item) => item.ItemNo === action.payload.ItemNo
      );
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    clearTableData: (state, action) => {
      return [];
    },
    // New reducer to replace the array with new data
    replaceTableData: (state, action) => {
      return action.payload; // Set the state to the new data
    },
  },
});

export const {
  addItemToTableData,

  deleteItemFromTableData,
  clearTableData,
  replaceTableData,

  updateTableRowValue,
} = purchaseReturnTableDataSlice.actions;
export default purchaseReturnTableDataSlice.reducer;
