import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const returnHeaderSlice = createSlice({
  name: "returnHeader",
  initialState: {
    customer: null,
    contact: 0,
    retNo: 0,
    BatchNo: 0,
    invo: null,
    date: dayjs(),
    dealer: null,
    outlet: { Typ: "shamil" },
    edit: false,
    retBill: 0,
    invtyp: "",
  },
  reducers: {
    updateSelected: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetState: () => {
      // Reset the state to its initial/default values
      return {
        customer: null,
        contact: 0,
        // retNo: 0,
        // BatchNo: 0,
        invo: 0,
        date: dayjs(),
        invtyp:{Typ:"B2B"}
        // dealer: null,
        // outlet: null
      };
    },
  },
});

export const { updateSelected, resetState } = returnHeaderSlice.actions;
export default returnHeaderSlice.reducer;
