// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import { store } from "store";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const state = store.getState();
  const isAccountsShow = state.common.isAccountsShow;
  console.log(state.common.isAccountsShow, "ppppppppppppppppp");

  const navItems = menuItem.items.map((item) => {
    if (!isAccountsShow && item.id === "accounts") {
      return null; // Return null to exclude it from rendering
    }
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  console.log("menuItem", menuItem);
  console.log(navItems);

  return <>{navItems}</>;
};

export default MenuList;
