import SalesLayOut from "views/Sales-Sections/SalesLayOut";
import SalesReturn from "views/Sales-Sections/salesReturn/SalesReturn";
import SalesPos from "views/Sales-Sections/salesPos/SalesPos";
import SalesPosB2C from "views/Sales-Sections/salesPosB2C/SalesPosB2C";

const SalesRoute = {
  path: "sales",
  element: <SalesLayOut />,
  children: [
    {
      path: "salesPos",
      element: <SalesPos typp="salesBill" />,
    },
    {
      path: "salesPosb2c",
      element: <SalesPosB2C />,
    },
    {
      path: "salesReturn",
      element: <SalesReturn typp="salesReturn" />,
    },
    {
      path: "orderForm",
      element: <SalesPos typp="salesBill" />,
    },

    // {
    //     path: 'print',
    //     element: <Print />
    // }
  ],
};
export default SalesRoute;
