import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  updateQtyAndTotal,
  addItemToTableData,
} from "views/Sales-Sections/salesReturn/slice/tableDataSlice";
import {
  Grid,
  TextField,
  Autocomplete,
  createFilterOptions,
  Dialog,
  DialogContent,
  Drawer,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import dayjs from "dayjs";
import DrawerItems from "views/Sales-Sections/common/DrawerItems";
import { useSnackBar } from "../../../../context/snackbarContext";
import BatchWindow from "./BatchWindowPos";
import MultiUnitWindow from "./MultiUnitWindow";
import { baseUrl } from "config";
import { redirectUrl } from "config";

const AddItemFileds = ({
  setUpperRef,
  openModal,
  currProduct,
  modalTyp,
  expiry,
  setDropDown,
  setModalTyp,
  setOpenModal,
  batchWise,
  allProducts,
  setBatchWise,
  setAllProducts,
  setExpiry,
}) => {
  //--------// APIS_USED_FOR_THIS_COMPONENT //---------//
  const getProduct = "/getItemNames";

  const { showSnackbar } = useSnackBar();
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state.tableData);
  const returnHeader = useSelector((state) => state.returnHeader);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerWidth = 455;
  const taxUtilitiesListData = useSelector(
    (state) => state.common.taxUtilitiesListData
  );

  // Fetch all products on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrl + getProduct, {
          withCredentials: true,
        });
        setAllProducts(response.data.result);

        const values = response.data.batchExpiry.split("-");
        setBatchWise(values[0]);
        setExpiry(values[1]);
      } catch (error) {
        if (
          (error.response && error.response.status === 403) ||
          error.response.status === 401
        ) {
          // Handle the error or navigate to another page as needed
          redirectUrl();
        } else {
          // Handle other types of errors
          console.error("API request failed:", error.message);
        }
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    clearValues();
  }, [tableData]);

  // Clear the Autocomplete value
  function clearValues() {
    setDropDown(null);
  }
  // Modal open
  const handleClickOpen = (typ) => {
    setModalTyp(typ);

    setOpenModal(true);
  };
  // Modal close
  const handleClose = () => {
    setOpenModal(false);
  };

  // Add product from Autocomplete

  const handleBarcode = (event) => {
    const barcodeValue = event.target.value;
    const TaxEnabled = taxUtilitiesListData?.find(
      (data) => data.Typ === "SALES_RETURN"
    );

    if (event.key === "Enter") {
      axios
        .post(
          `${baseUrl}/getProductByBarcode`,
          { barcode: barcodeValue },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.data[0]) {
            const barcodeItem = res.data.data[0];
            const existingItemIndex = tableData.findIndex(
              (item) => barcodeValue === item.ItemC
            );
            // if existing barcode in table increase quantity
            if (existingItemIndex !== -1) {
              const updatedQty = tableData[existingItemIndex].qty + 1;
              console.log(updatedQty);
              console.log(barcodeItem);
              console.log(
                ((1 * tableData[existingItemIndex].Rate1) / 100) * tableData[existingItemIndex].Tax1 +
                tableData[existingItemIndex].Rate1 * updatedQty
              );
              const updatedTotal =
                TaxEnabled.Nam === "YES"
                  ? updatedQty * tableData[existingItemIndex].Rate1
                  : ((1 * tableData[existingItemIndex].Rate1) / 100) * tableData[existingItemIndex].Tax1 +
                  tableData[existingItemIndex].Rate1 * updatedQty;

              dispatch(
                updateQtyAndTotal({
                  index: existingItemIndex,
                  qty: updatedQty,
                  total: updatedTotal,
                  // ((1 * barcodeItem.Rate1) / 100) * barcodeItem.Tax1 +
                  // barcodeItem.Rate1 * updatedQty,
                  taxAmt:
                    TaxEnabled.Nam === "YES"
                      ? (updatedQty * tableData[existingItemIndex].Rate1 * tableData[existingItemIndex].Tax1) /
                        (100 + tableData[existingItemIndex].Tax1)
                      : ((updatedQty * tableData[existingItemIndex].Rate1) / 100) *
                      tableData[existingItemIndex].Tax1,
                })
              );
              event.target.value = "";
            }
            // adding new item
            else {
              const newItem = {
                ...barcodeItem,
                qty: 1,
                taxAmt:
                  TaxEnabled.Nam === "YES"
                    ? (1 * tableData[existingItemIndex].Rate1 * tableData[existingItemIndex].Tax1) /
                      (100 + tableData[existingItemIndex].Tax1)
                    : ((1 * tableData[existingItemIndex].Rate1) / 100) * tableData[existingItemIndex].Tax1,
                total: tableData[existingItemIndex].taxAmt + tableData[existingItemIndex].Rate1 * tableData[existingItemIndex].qty, // <-- Fix this line
                exp_date: dayjs().format("DD/MM/YYYY"),
              };
              newItem.total =
                TaxEnabled.Nam === "YES"
                  ? newItem.Rate1 * newItem.qty
                  : newItem.taxAmt + newItem.Rate1 * newItem.qty;

              dispatch(addItemToTableData(newItem));
              event.target.value = ""; // Clear the TextField
            }
          } else {
            showSnackbar("warning", "This item not found");
          }
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            redirectUrl();
          }
        });
    }
  };
  // const handleBarcode = (event) => {
  //   const barcodeValue = event.target.value;

  //   if (event.key === "Enter") {
  //     const barcodeItem = allProducts.find(
  //       (product) => product.ItemC === barcodeValue
  //     );

  //     if (barcodeItem) {
  //       const existingItemIndex = tableData.findIndex(
  //         (item) => item.ItemC === barcodeItem.ItemC
  //       );

  //       if (existingItemIndex !== -1) {
  //         const updatedQty = tableData[existingItemIndex].qty + 1;
  //         const updatedTotal =
  //           ((1 * barcodeItem.Rate1) / 100) * barcodeItem.Tax1 +
  //           barcodeItem.Rate1 * updatedQty;

  //         dispatch(
  //           updateQtyAndTotal({
  //             index: existingItemIndex,
  //             qty: updatedQty,
  //             total: updatedTotal,
  //             taxAmt:
  //               ((updatedQty * barcodeItem.Rate1) / 100) * barcodeItem.Tax1,
  //           })
  //         );
  //       } else {
  //         const newItem = {
  //           ...barcodeItem,
  //           qty: 1,
  //           taxAmt: ((1 * barcodeItem.Rate1) / 100) * barcodeItem.Tax1,
  //           total: barcodeItem.taxAmt + barcodeItem.Rate1 * barcodeItem.qty, // <-- Fix this line
  //           exp_date: dayjs().format("DD/MM/YYYY"),
  //         };
  //         newItem.total = newItem.taxAmt + newItem.Rate1 * newItem.qty;

  //         dispatch(addItemToTableData(newItem));
  //       }

  //       event.target.value = ""; // Clear the TextField
  //     } else {
  //       showSnackbar("warning", "This item not found");
  //     }
  //   }
  // };

  const handleDrawerOpen = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 0 }}>
          {modalTyp === "multiUnit" ? (
            <MultiUnitWindow
              product={currProduct}
              modalClose={handleClose}
              batchWise={batchWise}
              expiry={expiry}
              setUpperRef={setUpperRef}
            />
          ) : modalTyp === "batchWise" ? (
            <BatchWindow
              product={currProduct}
              expiry={expiry}
              modalClose={handleClose}
              setUpperRef={setUpperRef}
            />
          ) : (
            // Render something else or handle other cases here
            <p>Other content</p>
          )}
        </DialogContent>
      </Dialog>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={4} sm={3}>
          <TextField
            color="info"
            label={"Barcode"}
            placeholder="Type barcode here...."
            fullWidth
            size="small"
            onChange={handleBarcode}
            onKeyDown={handleBarcode}
          />
        </Grid>
        {/* <Grid item xs={4} sm={3}>
          <Autocomplete
            id="combo-box-demo"
            value={dropDownValue}
            onChange={handleAutocompleteChange}
            options={allProducts}
            filterOptions={filterOptions}
            getOptionLabel={(option) =>
              `${option.ItemC} - ${option.ItemNameTextField}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                color="info"
                label="Product Name"
                size="small"
                placeholder="Search barcode or item name"
                fullWidth
              />
            )}
          />
        </Grid> */}
        <Grid item xs={1}>
          <IconButton
            aria-label="delete"
            id="nextButton"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="right"
            open={drawerOpen}
            variant="persistent"
            sx={{
              background: "rgb(238, 242, 246)",
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
              "@media (max-width: 600px)": {
                width: "100%",
                "& .MuiDrawer-paper": {
                  width: "100%",
                },
              },
            }}
          >
            <Box sx={{ background: "rgb(94 53 177)", p: 2 }}>
              <Typography variant="h3" sx={{ color: "#fff" }}>
                Choose Products
              </Typography>
            </Box>
            <Box sx={{ p: 1.5, background: "rgb(238, 242, 246)" }}>
              <DrawerItems
                typ="salesReturn"
                allProduct={allProducts}
                batchWise={batchWise}
                expiry={expiry}
              />
            </Box>
            <Box sx={{ position: "absolute", top: 5, right: 0 }}>
              <IconButton
                sx={{ mr: 2, color: "#fff", fontSize: "1.2rem" }}
                onClick={handleDrawerClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Drawer>
        </Grid>
        {returnHeader.edit === true ? (
          <Grid item xs={3} md={1}>
            <Box
              sx={{
                px: 1,
                py: 0.5,
                background: "orange",
                borderRadius: 2,
                textAlign: "center",
              }}
            >
              <Typography variant="captions" color={"CaptionText"}>
                Edit Mode
              </Typography>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

AddItemFileds.propTypes = {
  setUpperRef: PropTypes.func,
  setCurrProduct: PropTypes.func,
  currProduct: PropTypes.any,
  dropDownValue: PropTypes.any,
  setDropDown: PropTypes.func,
};
export default AddItemFileds;
