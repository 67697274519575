import {
  IconShoppingCartPlus,
  IconShoppingCart,
  IconTruckReturn,
} from "@tabler/icons";

const icons = {
  IconShoppingCart,
  IconTruckReturn,
  IconShoppingCartPlus,
};

const purchase = {
  id: "purchases",
  type: "group",
  children: [
    {
      id: "purchaseGroup",
      title: "Manage Purchase",
      type: "collapse",
      icon: icons.IconShoppingCart,
      children: [
        {
          id: "purchase",
          title: "Purchase",
          type: "item",
          url: "/purchase/purchasebill",
          icon: icons.IconShoppingCartPlus,
          breadcrumbs: false,
        },
        {
          id: "purchaseRetrun",
          title: "Purchase Return",
          type: "item",
          url: "/purchase/purchasebillReturn",
          icon: icons.IconTruckReturn,
          breadcrumbs: false,
        },
        {
          id: "purchaseImport",
          title: "Purchase Import",
          type: "item",
          url: "/purchase/purchaseImport",
          icon: icons.IconTruckReturn,
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default purchase;
