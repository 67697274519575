import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// dashboard routing

// utilities routing
const SubCategory = Loadable(lazy(() => import("views/others/SubCategory")));
const UserPrivilege = Loadable(
  lazy(() => import("views/others/UserPrivilege"))
);
const TaxUtilities = Loadable(lazy(() => import("views/others/TaxUtilities")));
const CurrencyRate = Loadable(lazy(() => import("views/others/CurrencyRate")));

const ThermalPrint = Loadable(
  lazy(() => import("views/PrintTypes/ThermalPrint"))
);

const A4Print = Loadable(lazy(() => import("views/PrintTypes/A4Print")));
const Error = Loadable(lazy(() => import("views/others/Error")));

const Others = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "others",
      children: [
        {
          path: "createcategory",
          element: <SubCategory />,
        },
        {
          path: "userPrivilege",
          element: <UserPrivilege />,
        },
        {
          path: "taxUtilities",
          element: <TaxUtilities />,
        },
        {
          path: "CurrencyRate",
          element: <CurrencyRate />,
        },
        {
          path: "thermalPrint",
          element: <ThermalPrint />,
        },
        {
          path: "a4",
          element: <A4Print />,
        },
      ],
    },
    { path: "*", element: <Error /> },
  ],
};

export default Others;
